<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ $t('bets') }} VS</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button :to="{ name: 'BetsCreate', params: { lang: lang, console: console } }" variant="info">
          <i class="fa fa-plus px-1"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="bets.data" :fields="columns" :filter="filter">
      <div slot="date" slot-scope="row">
        <span v-if="row.item.date">{{ row.item.date }} - {{ row.item.time }}</span>
      </div>
      <div slot="quantity" slot-scope="team" class="text-right">{{ team.value }}</div>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button v-if="row.item.confirm_local || row.item.confirm_visit" class="mx-1" variant="primary" :to="{ name: 'BetsConfirm', params: { lang: lang, console: console, betId: row.item.id } }"><i class="fa fa-check px-1"></i></b-button>
          <b-button class="mx-1" variant="primary" :to="{ name: 'BetsEdit', params: { lang: lang, console: console, betId: row.item.id } }"><i class="fa fa-pencil px-1"></i></b-button>
          <b-button class="mx-1" variant="danger" @click="openModalDestroy(row.item)"><i class="fa fa-trash px-1"></i></b-button>
        </div>
      </template>
    </b-table>
    <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="paginate"></b-pagination>
    <b-modal ref="modalDelete" :title="$t('confirmTitleDelete')" headerBgVariant="danger" headerTextVariant="light">
      <p>{{$t('confirmTextDelete')}}</p>
      <strong>{{ bet.name }}</strong>
      <div slot="modal-footer">
        <b-button @click="$refs.modalDelete.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="danger" @click="destroy()" class="ml-3">{{$t('delete')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      rows: 0,
      perPage: 25,
      bets: {},
      bet: {},
      filter: '',
      currentPage: 1,
      columns: [
        { key: 'console', label: this.$t('console'), sortable: true },
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'user_a_nick', label: this.transformCapital(this.$t('user') + ' A'), sortable: true },
        { key: 'user_b_nick', label: this.transformCapital(this.$t('user') + ' B'), sortable: true },
        { key: 'date', label: this.$t('date'), sortable: true },
        { key: 'bet', label: this.$t('bet'), sortable: true },
        { key: 'gain', label: this.$t('gain'), sortable: true },
        { key: 'actions', label: '' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'user'
    ])
  },
  mounted() {
    document.title = `${this.$t('bets')} VS`
    this.fetchData()
  },
  methods: {
    paginate (page) {
      this.$router.push({ name: 'Bets', params: { lang: this.lang, console: this.console }, query: { page } })
      this.fetchData()
    },
    transformCapital (text) {
      return `${text.charAt(0).toUpperCase()}${text.slice(1)}`
    },
    openModalDestroy (bet) {
      this.bet = bet
      this.$refs.modalDelete.show()
    },
    destroy () {
      const path = `auth/${this.lang}/${this.console}/super/bets/${this.bet.id}/delete`
      this.$axios.delete(path).then(() => {
        this.bets.data = this.bets.data.filter(b => {
          return b.id !== this.bet.id
        })
        this.$refs.modalDelete.hide()
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    fetchData () {
      const page = this.$route.query.page
      const params = {
        page: page ? page : 1
      }
      const path = `auth/${this.lang}/${this.console}/super/bets`
      this.$axios.get(path, { params }).then(response => {
        const data = response.data.data
        this.bets = data
        this.rows = data.total
        this.perPage = data.per_page
        this.currentPage = data.current_page
      })
    }
  }
}
</script>
